<template>
  <!-- 租车调度 -->
  <div class="CarRentalDispatch">
    <!-- tabs -->
    <div class="Tabs">
      <div class="Tab-line"></div>
      <div
        v-for="(item, index) in tabList"
        :key="item.id"
        :class="index == tabActive ? 'tabActive' : ''"
        class="Tabs-li"
        @click="tabClickFun(item, index)"
      >
        {{ `${item.text}` }}
      </div>
    </div>
    <div class="template">
      <component :is="loadComponent" @openInfoPopup="openInfoPopup" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      tabActive: 0,
      loadComponent: null,
      tabList: [
        {
          text: "待审核",
          value: 0,
          id: "t4",
          name: "checkCar",
        },
        {
          text: "待取车",
          value: 0,
          id: "t1",
          name: "takeCar",
        },
        {
          text: "待还车",
          value: 0,
          id: "t2",
          name: "returnCar",
        },
        {
          text: "待退押金",
          value: 0,
          id: "t5",
          name: "refundCar",
        },
        {
          text: "历史调度",
          value: 0,
          id: "t3",
          name: "historyCar",
        },
      ],
    };
  },
  mounted() {
    this.loadComponents("checkCar");
  },
  methods: {
    openInfoPopup() {},
    loadComponents(name) {
      this.loadComponent = () => import(`./rentalDispatchModel/${name}.vue`);
    },
    tabClickFun(item, index) {
      this.tabActive = index;
      document.querySelector(".Tab-line").style.transform =
        "translateX(" + index * 100 + "%)";
      this.loadComponents(item.name);
    },
  },
};
</script>

<style scoped lang="scss">
.tabActive {
  color: #336ffe !important;
}
.red {
  color: #d40000;
}
.yellow {
  color: #ffbd00;
}
.blue {
  color: #336ffe;
}
.CarRentalDispatch {
  position: relative;
  height: 100%;
  .template {
    margin-top: 24px;
    height: calc(100% - 24px - 54px);
  }
  .Tabs {
    height: 54px;
    background: #ffffff;
    box-shadow: 0px 0px 4px 0px rgba(52, 52, 52, 0.1);
    display: flex;
    position: relative;
    .Tab-line {
      width: 120px;
      height: 2px;
      background: #336ffe;
      display: block;
      position: absolute;
      z-index: 0;
      bottom: 0px;
      left: 0;
      transition: all 0.3s;
    }
    .Tabs-li {
      text-align: center;
      width: 90px;
      height: 100%;
      line-height: 54px;
      padding: 0 15px;
      color: #000000;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
</style>