var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "CarRentalDispatch" }, [
    _c(
      "div",
      { staticClass: "Tabs" },
      [
        _c("div", { staticClass: "Tab-line" }),
        _vm._l(_vm.tabList, function (item, index) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "Tabs-li",
              class: index == _vm.tabActive ? "tabActive" : "",
              on: {
                click: function ($event) {
                  return _vm.tabClickFun(item, index)
                },
              },
            },
            [_vm._v(" " + _vm._s(`${item.text}`) + " ")]
          )
        }),
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "template" },
      [
        _c(_vm.loadComponent, {
          tag: "component",
          on: { openInfoPopup: _vm.openInfoPopup },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }